



import React from 'react'
import Sidebar from '../components/sidebar'
// import Homedetail from '../components/homedetail'
import Alltooldetails from '../components/alltoolsdetail'

const Alltool = () => {
  return (
    <div>
     <Sidebar Homepage={Alltooldetails}/>
     {/* <Homedetail/> */}
     
    </div>
  )
}

export default Alltool